<template>
    <styled-slideout
        label="Add Creative"
        :value="value"
        :width="700"
        @input="$emit('input', $event)">
        <edit-creative-form
            :creative-ad="creativeAd"
            :reset="opened"
            @ad-created="adCreated" />
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import EditCreativeForm from '@/components/creative-library/EditCreativeForm';

export default {
    name: 'CreativeSlideout',
    components: {
        StyledSlideout,
        EditCreativeForm
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        creativeAd: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            opened: true,
        };
    },
    watch: {
        value(current, previous) {
            // When opening slideout
            if (current === true && previous === false) {
                this.opened = true;
            }
        },
    },
    methods: {
        adCreated() {
            this.$emit('ad-created');
        }
    }
};
</script>