<template>
    <div class="card stack">
        <div
            v-for="(asset, index) in assets"
            :key="asset.id">
            <div :class="{selectedCard: selected}">
                <div
                    v-if="assets[index].asset_type.name === IMAGE_ASSET_TYPE"
                    class="card__asset">
                    <img
                        style="width:100%"
                        :src="assets[index].url"
                        alt="">
                </div>
                <div
                    v-if="assets[index].asset_type.name === VIDEO_ASSET_TYPE"
                    class="card__asset">
                    <video
                        width="100%"
                        controls="controls"
                        preload="metadata">
                        <source
                            :src="`${assets[index].url}#t=0.5`"
                            type="video/mp4">
                    </video>
                </div>
                <div class="card__info">
                    <div class="card__title">
                        {{ title }}
                    </div>
                    <div class="card__icons">
                        <platform-icons :assets="assets" />
                    </div>
                </div>
                <div class="card__bottom">
                    <div class="card__link">
                        <a
                            href="#"
                            @click.prevent="edit(id)">View & Edit</a>
                    </div>
                    <div class="card__button">
                        <styled-button
                            :class="{gray: !selected}"
                            @click="select(id)">
                            SELECT<span v-if="selected">ED</span>
                        </styled-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import PlatformIcons from '@/components/creative-library/PlatformIcons.vue';
import {
    VIDEO_ASSET_TYPE,
    IMAGE_ASSET_TYPE
} from '@/components/ad-studio/store/constants.js';
export default {
    name: 'CreativeAdCard',
    components: {
        StyledButton,
        PlatformIcons
    },
    props: {
        assets: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        platforms: {
            type: Array,
            required: true
        },
        id: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            selected: false,
            IMAGE_ASSET_TYPE,
            VIDEO_ASSET_TYPE
        };
    },
    computed: {
        currentSelections() {
            return this.$store.state.creativeLibrary.selectedCreatives;
        }
    },
    mounted() {
        this.checkSelection(this.id);
    },
    methods: {
        checkSelection(id) {
            if (this.currentSelections.has(id)) {
                this.selected = true;
            } else {
                this.selected = false;
            }
        },
        select(id) {
            this.$emit('selected', id);
            this.checkSelection(id);
        },
        edit(id) {
            this.$emit('edit', id);
        }
    }
};
</script>

<style lang="scss" scoped>
.card{
    width: calc(25% - 56px);
    vertical-align: top;
    margin:28px;
}
.card__asset{
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
}
.card__icons{
    margin-top: 15px;
}
.card__social{
    display: inline-block;
    position: relative;
    img{
        max-width: 15px;
        max-height: 15px;
        margin: 0 3px;
    }
}
.card__info{
    background: white;
    padding: 20px 15px;
    margin-top: -5px;
    align-items: center;
    min-height: 45px;
}
.card__title{
    font-size: 15px;
    font-weight: 600;
    color: #00A2EA;
}
.selectedCard{
    box-shadow: 4px 4px $blue-primary, -4px 4px $blue-primary, 4px -4px $blue-primary, -4px -4px $blue-primary;
}
.card__bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-top: 1px solid #d9dada;
    background-color: white;
    button{
        height: 40px;
    }
}

.stack {
    display: inline-grid;
    place-items: center;
    align-items: flex-end
}

.stack>* {
    grid-column-start: 1;
    grid-row-start: 1;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.stack>*:nth-child(3) {
    transform: rotate(3deg);
    z-index: 1;
}

.stack>*:nth-child(2) {
    transform: rotate(-3deg);
    z-index: 2;
}

.stack>*:nth-child(1) {
    transform: rotate(0deg);
    z-index: 3;
}
</style>
