<template>
    <styled-interface
        hide-toolbar
        no-padding>
        <creative-slideout
            v-model="openSlideout"
            :creative-ad="adForEditing"
            @ad-created="adCreated" />
        <div class="main">
            <div class="top with-padding">
                <div class="top__line flex">
                    <div>
                        <h2>Creative Library</h2>
                    </div>
                    <div class="flex justify-end">
                        <styled-button
                            class="gray creative-button"
                            @click="addNew()">
                            ADD NEW
                        </styled-button>
                    </div>
                </div>
            </div>
            <div class="with-padding">
                <div class="subhead flex">
                    <div class="playbooks-select">
                        <div
                            v-if="playbook"
                            class="clear-playbooks"
                            @click="clearSelection">
                            <div />
                            <div />
                        </div>
                        <v-select
                            v-model="playbook"
                            :items="playbooks"
                            :loading="playbooksLoading"
                            :item-text="'display_name'"
                            :item-value="'id'"
                            label="Playbook"
                            class="styled-field" />
                    </div>
                    <div>
                        <v-text-field
                            v-model="filter"
                            label="Search"
                            placeholder="Enter keywords..."
                            class="styled-field" />
                    </div>
                    <div class="flex justify-end">
                        <div class="selected showing-title">
                            <span class="light-blue--text font-weight-bold">{{ count }}</span> Selected
                        </div>
                        <styled-button
                            class="creative-button"
                            :disabled="!count"
                            @click="deploy()">
                            DEPLOY
                        </styled-button>
                    </div>
                </div>
                <div class="creative-wrapper">
                    <loader
                        v-if="loadingAds"
                        class="pa-5" />
                    <div v-if="filteredAds.length && !loadingAds">
                        <creative-ad-card
                            v-for="ad in filteredAds"
                            :id="ad.id"
                            :key="ad.id"
                            :assets="ad.full_configuration.creatives[0].assets"
                            :title="ad.display_name"
                            :platforms="ad.full_configuration.platforms"
                            @edit="edit"
                            @selected="isSelected" />
                    </div>
                    <div
                        v-if="!filteredAds.length && !loadingAds"
                        class="with-padding">
                        <h2>Sorry, nothing found...</h2>
                    </div>
                </div>
            </div>
        </div>
    </styled-interface>
</template>

<script>
import { mapActions } from 'vuex';
import StyledInterface from '@/components/globals/StyledInterface';
import CreativeAdCard from '@/components/creative-library/CreativeAdCard';
import CreativeSlideout from '@/components/creative-library/CreativeSlideout';
import StyledButton from '@/components/globals/StyledButton';
import Loader from '@/components/globals/Loader';

export default {
    name: 'CreativeLibrary',
    title: 'Creative Library',
    components: {
        StyledInterface,
        CreativeAdCard,
        CreativeSlideout,
        StyledButton,
        Loader
    },
    data() {
        return {
            filter: '',
            nothingFound: false,
            count: 0,
            playbook: '',
            playbooks: [],
            playbookAds: [],
            openSlideout: false,
            adForEditing: null,
            loadingAds: false,
            allPlaybooks: [],
            playbookIds: new Set(),
            adIds: new Set(),
            syncedPlaybooks: [],
            playbooksLoading: false
        };
    },
    computed: {
        ads() {
            return this.$store.state.creativeLibrary.creatives;
        },
        filteredAds() {
            return this.playbookAds.filter(ad => {
                return ad.display_name.toLowerCase().includes(this.filter.toLowerCase());
            });
        },
    },
    watch: {
        filteredAds() {
            if (this.filteredAds.length === 0) {
                this.nothingFound = true;
            } else {
                this.nothingFound = false;
            }
        },
        playbook() {
            if (this.playbook) {
                const syncedAds = this.syncedPlaybooks.filter(p => p.playbook_id == this.playbook);
                this.adIds.clear();
                syncedAds.forEach(a => {
                    this.adIds.add(a.ad_id);
                });
                this.playbookAds = this.ads.filter(ad => this.adIds.has(ad.id));
            } else {
                this.playbookAds = this.ads;
            }
        },
        ads() {
            this.playbook = '';
            this.adIds.clear();
            this.playbookAds = this.ads;
        }
    },
    async mounted() {
        await this.getAds();
        await this.initPlaybooks();
    },
    methods: {
        ...mapActions(['getAdsFromApi']),
        async getAllPlaybooks() {
            try {
                const response = await this.$apiRepository.getPlaybooks();
                this.allPlaybooks = response.data.data;
            } catch(error) {
                console.log('Error:', error);
                this.errorMessage = error;
            }
        },
        async getSyncedPlaybooks() {
            try {
                const response = await this.$apiRepository.getAdPlaybooks();
                this.syncedPlaybooks = response.data.data;
                this.playbookIds.clear();
                this.syncedPlaybooks.forEach(p => {
                    this.playbookIds.add(p.playbook_id);
                });
                this.playbooks = this.allPlaybooks.filter(p => this.playbookIds.has(p.id));
            } catch(error) {
                console.log('Error:', error);
                this.errorMessage = error;
            }
        },
        isSelected(id) {
            this.$store.dispatch('addCreativesForDeployment', id);
            this.count = this.$store.state.creativeLibrary.selectedCreatives.size;
        },
        edit(id) {
            this.adForEditing = this.ads.filter(creative => creative.id == id)[0];
            this.openSlideout = true;
        },
        addNew() {
            this.openSlideout = true;
            this.adForEditing = null;
        },
        deploy() {
            this.$router.push({ name: 'creative-deployment' });
        },
        async adCreated() {
            this.openSlideout = false;
            await this.getAds();
            await this.initPlaybooks();
        },
        async getAds() {
            this.loadingAds = true;
            await this.getAdsFromApi();
            this.loadingAds = false;
        },
        async initPlaybooks() {
            this.playbooksLoading = true;
            await this.getAllPlaybooks();
            await this.getSyncedPlaybooks();
            this.playbooksLoading = false;
        },
        clearSelection() {
            this.playbook = '';
        }
    }
};
</script>

<style lang="scss" scoped>
.main{
    width:100%;
    background: #F7F8FC;
}
.with-padding{
    padding: 20px 35px;
}
.creative-button{
    width: 150px;
}
.styled-field{
    width: 400px;
}
.top{
    background: #ECF2F5;
}
.flex{
    display: flex;
    align-items: center;
}
.top__line{
    justify-content: space-between;
}
.justify-end{
    justify-content: flex-end;
}
.subhead{
    width: 100%;
    justify-content: space-between;
}
.selected{
    margin-right: 30px;
    font-size: 16px;
}
.creative-wrapper{
    width: calc(100% + 60px);
    margin-left: -30px;
}
.playbooks-select{
    position: relative;
    margin-right: 30px;
}
.clear-playbooks{
    position: absolute;
    top:2px;
    cursor: pointer;
    right:35px;
    width: 30px;
    height: 30px;
    z-index: 1;
    div{
        position: absolute;
        transform: rotate(45deg) translate(10px, -10px);
        width: 2px;
        height: 30px;
        background: $gray-dark;
        &:nth-of-type(2){
            transform: rotate(-45deg) translate(10px, 10px);
        }
    }
}
</style>
