<template>
    <v-form
        ref="form"
        v-model="valid"
        class="settings">
        <loader
            v-if="isLoading" />
        <div v-else>
            <!-- media -->
            <p class="section-title">
                Ad
            </p>
            <v-select
                v-model="type"
                :items="types"
                label="Type"
                class="styled-field mb-3" />
            <div class="images-error">
                {{ mediaError }}
            </div>
            <div
                v-if="type === 'Video'"
                class="videos">
                <upload-video-asset
                    :icons="['facebook', 'snapchat', 'tiktok']"
                    editable
                    aspect-ratio="9:16"
                    :asset.sync="nineBySixteenVideoAsset" />
                <upload-video-asset
                    :icons="['pinterest']"
                    editable
                    aspect-ratio="2:3"
                    :asset.sync="twoByThreeVideoAsset" />
                <upload-video-asset
                    :icons="['facebook']"
                    editable
                    aspect-ratio="4:5"
                    :asset.sync="fourByFiveVideoAsset" />
            </div>

            <div
                v-if="type === 'Image'"
                class="images">
                <upload-image-asset
                    :icons="['facebook', 'snapchat']"
                    editable
                    aspect-ratio="9:16"
                    :asset.sync="nineBySixteenImageAsset" />
                <upload-image-asset
                    :icons="['pinterest']"
                    editable
                    aspect-ratio="2:3"
                    :asset.sync="twoByThreeImageAsset" />
                <upload-image-asset
                    :icons="['facebook']"
                    editable
                    aspect-ratio="1:1"
                    :asset.sync="oneByOneImageAsset" />
            </div>
            <!-- end of media -->

            <!-- content -->
            <p class="section-title">
                Content
            </p>
            <div class="input-holder">
                <platform-icons :platforms="['snapchat', 'tiktok', 'facebook']" />
                <v-text-field
                    v-model="name"
                    required
                    :rules="[...requiredRules]"
                    label="Ad Name"
                    class="styled-field mb-3"
                    @input="clearErrors" />
            </div>
            <div class="input-holder">
                <platform-icons :platforms="['snapchat', 'tiktok', 'facebook']" />
                <v-text-field
                    v-model="headline"
                    required
                    :rules="requiredRules"
                    label="Headline"
                    class="styled-field mb-3"
                    @input="clearErrors" />
            </div>
            <div class="input-holder">
                <platform-icons :platforms="['snapchat', 'tiktok', 'facebook']" />
                <v-textarea
                    v-model="description"
                    required
                    :rules="requiredRules"
                    label="Description"
                    class="styled-field mb-3"
                    @input="clearErrors" />
            </div>
            <div class="input-holder">
                <v-select
                    v-model="cta_text"
                    required
                    :rules="requiredRules"
                    :items="actions"
                    label="Call to action text"
                    class="styled-field mb-3"
                    @change="clearErrors" />
            </div>
            <div
                v-if="activeDeploymentChannels.includes('tiktok')"
                class="input-holder">
                <platform-icons :platforms="['tiktok']" />
                <div class="layout align-center">
                    <div class="flex">
                        <v-select
                            v-model="selectedTikTokIdentity"
                            return-object
                            :items="tikTokIdentities"
                            :rules="requiredRules"
                            :loading="tikTokIdentityLoading"
                            placeholder="Select identity"
                            :item-text="'display_name'"
                            label="Identity"
                            class="styled-field mb-3" />
                    </div>
                    <div class="flex shrink mb-3">
                        <action-button
                            size="18"
                            icon="refresh"
                            @click="getTikTokIdentities" />
                    </div>
                </div>


                <styled-callout
                    v-if="(tikTokIdentities.length === 0) && !tikTokIdentityLoading"
                    type="error">
                    <div class="layout align-center">
                        <div class="flex shrink">
                            <status-icon
                                class="mr-3"
                                value="warning"
                                size="23" />
                        </div>
                        <div>
                            There are no TikTok Identities created for this account. Please go into TikTok Ads Manager and create one so it can be used during Ad Deployment as it is a required field.
                        </div>
                    </div>
                </styled-callout>
            </div>
            <!-- end of content -->

            <!-- details -->
            <div>
                <p class="section-title">
                    Details
                </p>
                <v-text-field
                    v-model="title"
                    required
                    :rules="requiredRules"
                    label="Title"
                    class="styled-field mb-3"
                    @input="clearErrors" />
                <v-autocomplete
                    v-model="selectedPlaybooks"
                    :items="playbooks"
                    :item-text="'display_name'"
                    :item-value="'id'"
                    label="Playbook"
                    class="styled-field mb-4"
                    chips
                    multiple />
                <!-- <v-select
                    v-model="selectedPlatforms"
                    :items="platforms"
                    label="Target Channels"
                    class="styled-field"
                    chips
                    multiple /> -->
            </div>

            <div
                class="save">
                <div class="error-message">
                    {{ errorMessage }}
                </div>
                <styled-button
                    type="submit"
                    class="start-posting mt-4"
                    @click.prevent="submit">
                    <span v-if="!editable">SAVE</span>
                    <span v-else>UPDATE</span>
                </styled-button>
            </div>

            <div
                v-if="editable"
                class="delete">
                <a
                    class="error-message"
                    @click.prevent="deleteAd">Delete Creative</a>
            </div>
        </div>
    </v-form>
</template>

<script>
import { mapState } from 'vuex';
import StyledButton from '@/components/globals/StyledButton';
import Loader from '@/components/globals/Loader';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledCallout from '@/components/globals/StyledCallout';
import ActionButton from '@/components/globals/ActionButton';
import UploadVideoAsset from '@/components/creative-library/UploadVideoAsset';
import UploadImageAsset from '@/components/creative-library/UploadImageAsset';
import PlatformIcons from '@/components/creative-library/PlatformIcons.vue';
import {
    requiredRules,
    urlRules
} from '@/helpers/validationRules';

export default {
    name: 'EditCreativeForm',
    components: {
        StyledCallout,
        StyledButton,
        StatusIcon,
        ActionButton,
        Loader,
        UploadImageAsset,
        UploadVideoAsset,
        PlatformIcons
    },
    props: {
        creativeAd: {
            type: Object,
            required: false,
            default: () => ({})
        },
        activeChannels: {
            type: Array,
            required: false,
            default: () => []
        },
        reset: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            id: null,
            valid: false,
            type: 'Image',
            types: ['Image', 'Video'],
            fourByFiveVideoAsset: null,
            nineBySixteenVideoAsset: null,
            twoByThreeVideoAsset: null,
            oneByOneImageAsset: null,
            nineBySixteenImageAsset: null,
            twoByThreeImageAsset: null,
            mediaError: '',
            changeTextTrigger: 1,
            assetIds: [],
            name: '',
            description: '',
            headline: '',
            cta_text: 'Learn More',
            actions: ['Learn More', 'Shop Now'],
            cta_link: '',
            title: '',
            selectedPlaybooks: [],
            playbooks: [],
            initialPlaybooks: [],
            syncedPlaybooks: [],
            platforms: ['Facebook', 'Pinterest', 'Snapchat', 'Tiktok'],
            activeDeploymentChannels: [],
            // selectedPlatforms: [],
            errorMessage: '',
            channels: new Set(),
            editable: false,
            isLoading: false,
            requiredRules,
            createdAdId: null,
            urlRules,
            tikTokIdentityLoading: false,
            selectedTikTokIdentity: null,
            tikTokIdentities: []
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentAgency: (state) => state.agency.currentAgency,
        }),
    },
    watch: {
        type() {
            this.mediaError = '';
            this.errorMessage = '';
        },
    },
    created() {
        if (this.activeChannels) {
            this.activeDeploymentChannels = Array.from(this.activeChannels);
        }
    },
    async mounted() {
        this.isLoading = true;
        await this.getPlaybooks();
        await this.getSyncedPlaybooks();
        this.isLoading = false;

        if (!this.creativeAd) {
            this.resetFields();
        } else {
            this.prePopulateFields();
        }

        if (this.activeDeploymentChannels.includes('tiktok')) {
            await this.getTikTokIdentities();
        }
    },
    methods: {
        async getPlaybooks() {
            try {
                const response = await this.$apiRepository.getPlaybooks();
                this.playbooks = response.data.data;
            } catch(error) {
                console.log('Error:', error);
                this.errorMessage = error;
            }
        },
        async getSyncedPlaybooks() {
            try {
                const response = await this.$apiRepository.getAdPlaybooks();
                this.syncedPlaybooks = response.data.data;
                console.log(this.syncedPlaybooks);
                const currentAdPlaybooks = this.syncedPlaybooks.filter(p => p.ad_id == this.creativeAd?.id);
                const playbooksIds = [];
                currentAdPlaybooks.forEach(p => {
                    playbooksIds.push(p.playbook_id);
                });
                const currentAdPlaybooksFormatted = this.playbooks.filter(p => playbooksIds.includes(p.id));
                this.selectedPlaybooks = currentAdPlaybooksFormatted;
                this.initialPlaybooks = currentAdPlaybooksFormatted;
            } catch(error) {
                console.log('Error:', error);
                this.errorMessage = error;
            }
        },
        validateFields() {
            this.errorMessage = '';
            this.$refs.form.validate();
            if (!this.valid) {
                this.errorMessage = 'Please provide all required data';
                return;
            }

            if (this.type === 'Image') {
                if (!(this.oneByOneImageAsset || this.nineBySixteenImageAsset || this.twoByThreeImageAsset)) {
                    this.errorMessage = this.mediaError = 'Please upload a photo';
                    return;
                }
            }

            if (this.type === 'Video') {
                if (!(this.fourByFiveVideoAsset || this.nineBySixteenVideoAsset || this.twoByThreeVideoAsset)) {
                    this.errorMessage = this.mediaError = 'Please upload a video';
                    return;
                }
            }

            return true;
        },
        async submit() {
            if (this.editable) {
                this.editAd();
                return;
            }

            if (this.validateFields()) {
                this.createNewAd();
            }

        },
        buildAdData() {
            // Make sure that we have correct ids if we change type during editing
            if (this.type == 'Image') {
                this.assetIds = [this.oneByOneImageAsset?.id, this.nineBySixteenImageAsset?.id, this.twoByThreeImageAsset?.id].filter(x => x);
            }
            if (this.type == 'Video') {
                this.assetIds = [this.fourByFiveVideoAsset?.id, this.nineBySixteenVideoAsset?.id, this.twoByThreeVideoAsset?.id].filter(x => x);
            }

            return {
                id: this.creativeAd?.id,
                display_name: this.title,
                public: true,
                configuration: {
                    name: this.name,
                    // platforms: this.selectedPlatforms,
                    headline: this.headline,
                    render_type: this.type,
                    description: this.description,
                    creatives: [
                        {
                            call_to_action_link : this.cta_link,
                            call_to_action_text : this.cta_text,
                            asset_ids: this.assetIds,
                        }
                    ]
                }
            };
        },
        async createNewAd() {
            this.isLoading = true;
            await this.createAd(this.buildAdData());
            await this.syncPlaybooks(this.createdAdId);
            this.isLoading = false;
            this.resetFields();
            this.$emit('ad-created');
        },
        async createAd(data) {
            try {
                const response = await this.$apiRepository.createAd(data);
                this.createdAdId = response.data.data.id;
            } catch (e) {
                this.errorMessage = 'An error occurred while creating your ad. Please contact support.';
                return;
            }
        },
        async syncPlaybooks(currentAdId) {
            const selectedPlaybooksIds = [];
            this.selectedPlaybooks.forEach(p => {
                // we need this check because v-select is not setting values initially
                if(typeof p !== 'number') {
                    console.log('playbooks has not been modified');
                    return;
                } else {
                    selectedPlaybooksIds.push(p);
                }
            });
            if (selectedPlaybooksIds.length) {
                const finalPlaybooks = this.playbooks.filter(p => selectedPlaybooksIds.includes(p.id));
                const playBooksForDeleting = this.initialPlaybooks.filter(p => !finalPlaybooks.includes(p));
                const newPlaybooks = finalPlaybooks.filter(p => !this.initialPlaybooks.includes(p));

                newPlaybooks.forEach(p => {
                    try {
                        this.$apiRepository.createAdPlaybook(
                            {
                                ad_id: currentAdId,
                                playbook_id: p.id
                            }
                        );
                    } catch (e) {
                        this.errorMessage = 'An error occurred while creating your ad. Please contact support.';
                        return;
                    }
                });

                playBooksForDeleting.forEach(p => {
                    const getId = this.syncedPlaybooks.filter(playbook => {
                        if (playbook.ad_id == currentAdId && playbook.playbook_id == p.id) {
                            return playbook;
                        }
                    });
                    try {
                        this.$apiRepository.deleteAdPlaybook(getId[0].id);
                    } catch (e) {
                        this.errorMessage = 'An error occurred while deleting your ad. Please contact support.';
                        return;
                    }
                });
            }
        },
        clearErrors() {
            this.errorMessage = '';
            this.$emit('clear-errors');
        },
        addChannel(event) {
            if (event.target.checked) {
                this.channels.add(event.target.value);
            } else {
                this.channels.delete(event.target.value);
            }
        },
        async deleteAd() {
            this.isLoading = true;
            const data = {
                id: this.creativeAd?.id
            };
            try {
                await this.$apiRepository.deleteAd(data);
            } catch (e) {
                this.errorMessage = 'An error occurred while deleting your ad. Please contact support.';
                console.log(e);
                return;
            } finally {
                this.isLoading = false;
            }
            this.$emit('ad-created');
        },
        async editAd() {
            if (!this.validateFields()) {
                return;
            }

            this.isLoading = true;
            await this.syncPlaybooks(this.creativeAd?.id);
            try {
                await this.$apiRepository.editAd(this.buildAdData());
            } catch (e) {
                this.errorMessage = 'An error occurred while editing your ad. Please contact support.';
                console.log(e);
                return;
            }
            this.$emit('ad-created');
            this.isLoading = false;
        },
        prePopulateFields() {
            if (!this.creativeAd) {
                return;
            }

            this.editable = true;
            //clear errors
            this.errorMessage = '';
            this.mediaError = '';

            //set fields
            this.id = this.creativeAd.id;
            this.type = this.creativeAd?.full_configuration?.creatives[0]?.assets[0]?.asset_type.display_name;

            // Images
            this.oneByOneImageAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '1:1' && asset.asset_type_id == 1)[0];
            this.nineBySixteenImageAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '9:16' && asset.asset_type_id == 1)[0];
            this.twoByThreeImageAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '2:3' && asset.asset_type_id == 1)[0];

            // Videos
            this.fourByFiveVideoAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '4:5' && asset.asset_type_id == 2)[0];
            this.nineBySixteenVideoAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '9:16' && asset.asset_type_id == 2)[0];
            this.twoByThreeVideoAsset = this.creativeAd?.full_configuration?.creatives[0]?.assets.filter(asset => asset.aspect_ratio === '2:3' && asset.asset_type_id == 2)[0];

            this.name = this.creativeAd?.full_configuration?.name;
            this.description = this.creativeAd?.full_configuration?.description;
            this.headline = this.creativeAd?.full_configuration?.headline;
            this.cta_text = this.creativeAd?.full_configuration?.creatives[0]?.call_to_action_text;
            this.cta_link = this.creativeAd?.full_configuration?.creatives[0]?.call_to_action_link;
            this.title = this.creativeAd?.display_name;
            // this.selectedPlatforms = this.creativeAd?.configuration?.platforms;
            this.assetIds = this.creativeAd?.full_configuration?.creatives[0]?.asset_ids;
            //this.playbook = this.creativeAd?.playbook; // todo map the playbooks to the ad

            this.$nextTick(function() {
                this.$refs.checkboxes?.children.forEach(checkboxComponent => {
                    if (this.creativeAd.platforms?.includes(checkboxComponent.childNodes[1].value)) {
                        checkboxComponent.childNodes[1].checked = true;
                        this.channels.add(checkboxComponent.childNodes[1].value);
                    } else {
                        checkboxComponent.childNodes[1].checked = false;
                        this.channels.delete(checkboxComponent.childNodes[1].value);
                    }
                });
            });
        },
        resetFields() {
            this.editable = false,
            this.type = 'Image';
            this.oneByOneImageAsset = null;
            this.nineBySixteenImageAsset = null;
            this.twoByThreeImageAsset = null;
            this.fourByFiveVideoAsset = null;
            this.nineBySixteenVideoAsset = null;
            this.twoByThreeVideoAsset = null;
            this.description = '';
            this.headline = '';
            this.cta_text = 'Learn More';
            this.cta_link = '';
            this.title = '';
            this.name = '';
            this.selectedPlaybooks = [];
            // this.selectedPlatforms = [];
        },
        async getTikTokIdentities() {
            this.tikTokIdentityLoading = true;
            this.tikTokIdentities = [];
            try {
                const response = await this.$http.get(`/dealers/${this.currentDealer.id}/platforms/tiktok/identities`);
                response.data.forEach(tikTokIdentity => {
                    this.tikTokIdentities.push({
                        'display_name': tikTokIdentity?.display_name,
                        'id': tikTokIdentity?.identity_id,
                        'identity_type': tikTokIdentity?.identity_type
                    });
                });

                this.selectedTikTokIdentity = this.tikTokIdentities[0];
            } catch(error) {
                this.tikTokIdentityLoading = false;
                console.log(error);
            } finally{
                this.tikTokIdentityLoading = false;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.settings{
    padding: 0 30px 30px 30px;
}
.section-title {
    font-size: 16px;
    background: #f3f5f6;
    font-weight: 600;
    margin-left: -30px;
    margin-right: -30px;
    padding: 5px 0;
    padding-left: 30px;
}
.checkboxes{
    display: flex;
    margin-bottom: 30px;
    label{
        margin-right: 30px;
    }
}
.icons{
    display: block;
    text-align: right;
    position: relative;
    img{
        max-width: 15px;
        max-height: 15px;
        margin: 0 3px;
    }
}
.images, .videos{
    margin-bottom: 30px;
    label{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        cursor: pointer;
    }
    button{
        width:150px;
        height: 40px;
        position: relative;
    }
}
.images{
    display: flex;
}
.videos{
    display: flex;
    justify-content: space-between;
}
input[type="file"]{
    display: none;
}
.portrait_preview{
    max-width: 225px;
    max-height: 400px;
}
.square_preview{
    max-width: 225px;
    max-height: 225px;
}
.images-error, .error-message{
    color:red;
    font-size: 16px;
    margin-bottom: 5px;
}
.save{
    text-align: center;
    button{
        width: 180px;
        font-size: 18px;
    }
}
.temporary-note{
    text-align: center;
}
.video{
    video{
        max-width: 300px;
        height: auto;
    }
}
.delete{
    margin-top: 25px;
    text-align: center;
}
</style>